import React, { useEffect, useState } from 'react';
import ApiService from '../../network/ApiService';
import AuthService from '../../network/AuthService';
import Navigator from "@components/RefereeMatchCard/Navigator";
import styles from './styles.module.scss';
import Spring from '@components/Spring';
import ScrollContainer from '@components/ScrollContainer';
import MatchCard from "@components/RefereeMatchCard";
import { useThemeProvider } from '@contexts/themeContext';
import dayjs from 'dayjs';

const Matches = () => {
    const { direction } = useThemeProvider();
    const [selectedDay, setSelectedDay] = useState(parseInt(dayjs().format('DD')));
    const [matches, setMatches] = useState([]);
    const apiService = new ApiService();
    const [team, setTeam] = useState(null);

    useEffect(() => {
        const fetchMatches = async () => {
            try {
                const teamID = new AuthService().getTeamID();
                if (!teamID) return;

                const response = await apiService.get(`teams/${teamID}/matches`);
                console.log('Fetched matches:', response);
                if (response) {
                    setMatches(response);

                    const team = await apiService.get(`teams/${teamID}`);
                    setTeam(team);
                }
            } catch (error) {
                console.error('Failed to fetch matches:', error);
            }
        };

        fetchMatches();
    }, []);

    // Filter matches based on the selected day and exclude completed or cancelled matches
    const filteredMatches = matches
        .filter(match => {
            const matchDate = match.details?.date ? new Date(match.details.date) : null; // Handle null dates
            if (!matchDate || isNaN(matchDate.getTime())) {
                console.error("Invalid match date:", match.details?.date);
                return false; // Exclude invalid or missing dates
            }
            return (
                matchDate.getDate() === selectedDay &&
                !['submitted', 'done'].includes(match.status)
            );
        })
        .sort((a, b) => new Date(a.details.date) - new Date(b.details.date));

    return (
        <Spring className="card d-flex flex-column">
            <div className="card_header d-flex flex-column g-10" style={{ paddingBottom: 10}}>
                <Navigator active={selectedDay} setActive={setSelectedDay} assignments={matches} />
            </div>
            <div className={styles.grid}>
                <div className={styles.scroll}>
                    <ScrollContainer height={0}>
                        <div className={`${styles.scroll_track} ${styles[direction]} track d-flex flex-column g-20`}>
                            {filteredMatches.map((match, index) => (
                                <MatchCard
                                    match={match}
                                    index={index}
                                    key={index} />
                            ))}
                        </div>
                    </ScrollContainer>
                </div>
            </div>
        </Spring>
    );
};

export default Matches;
