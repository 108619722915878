import ApiService from './ApiService';

class MatchController {
    constructor() {
        this.apiService = new ApiService();
    }

    /**
     * Create a new match
     * @param {Object} matchData - The data of the match to be created
     * @returns {Promise<Object>} - The newly created match object
     */
    async createMatch(matchData) {
        return this.apiService.post('matches', matchData);
    }

    /**
     * Get all matches
     * @returns {Promise<Object[]>} - Array of match objects
     */
    async getAllMatches() {
        return this.apiService.get('matches');
    }

    /**
     * Get a match by ID
     * @param {string} matchId - The UUID of the match
     * @returns {Promise<Object>} - The match object
     */
    async getMatchById(matchId) {
        return this.apiService.get(`matches/${matchId}`);
    }

    /**
     * Update a match by ID
     * @param {string} matchId - The UUID of the match
     * @param {Object} matchData - The updated match data
     * @returns {Promise<Object>} - The updated match object
     */
    async updateMatch(matchId, matchData) {
        return this.apiService.patch(`matches/${matchId}`, matchData);
    }

    /**
     * Delete a match by ID
     * @param {string} matchId - The UUID of the match
     * @returns {Promise<void>} - Response status indicating successful deletion
     */
    async deleteMatch(matchId) {
        return this.apiService.delete(`matches/${matchId}`);
    }

    /**
     * Add a goal to a match
     * @param {string} matchId - The UUID of the match
     * @param {Object} goalData - The details of the goal
     * @returns {Promise<Object>} - Response status
     */
    async addGoal(matchId, goalData) {
        return this.apiService.post(`matches/${matchId}/goal`, goalData);
    }

    /**
     * Add a red card to a player in a match
     * @param {string} matchId - The UUID of the match
     * @param {Object} cardData - The details of the red card
     * @returns {Promise<Object>} - Response status
     */
    async addRedCard(matchId, cardData) {
        return this.apiService.post(`matches/${matchId}/redCard`, cardData);
    }

    /**
     * Add a yellow card to a player in a match
     * @param {string} matchId - The UUID of the match
     * @param {Object} cardData - The details of the yellow card
     * @returns {Promise<Object>} - Response status
     */
    async addYellowCard(matchId, cardData) {
        return this.apiService.post(`matches/${matchId}/yellowCard`, cardData);
    }

    /**
     * Add a yellow-red card to a player in a match
     * @param {string} matchId - The UUID of the match
     * @param {Object} cardData - The details of the yellow-red card
     * @returns {Promise<Object>} - Response status
     */
    async addYellowRedCard(matchId, cardData) {
        return this.apiService.post(`matches/${matchId}/yellowRedCard`, cardData);
    }

    /**
     * Add a player to the home blanket
     * @param {string} matchId - The UUID of the match
     * @param {string} playerId - The UUID of the player to be added
     * @param {number} playerNumber - The overridden player number
     * @param {Object} [coach] - The coach details, if available
     * @returns {Promise<Object>} - Response status
     */
    async addPlayerToHomeBlanket(matchId, playerId, playerNumber, coach) {
        const playerData = {
            playerId,
            number: playerNumber,
            coach // Coach will be added only if provided
        };
        return this.apiService.post(`matches/${matchId}/homeBlankett/addPlayer`, playerData);
    }

    /**
     * Add a player to the away blanket
     * @param {string} matchId - The UUID of the match
     * @param {string} playerId - The UUID of the player to be added
     * @param {number} playerNumber - The overridden player number
     * @param {Object} [coach] - The coach details, if available
     * @returns {Promise<Object>} - Response status
     */
    async addPlayerToAwayBlanket(matchId, playerId, playerNumber, coach) {
        const playerData = {
            playerId,
            number: playerNumber,
            coach // Coach will be added only if provided
        };
        return this.apiService.post(`matches/${matchId}/awayBlankett/addPlayer`, playerData);
    }

    /**
     * Remove a player from the home blanket
     * @param {string} matchId - The UUID of the match
     * @param {string} playerId - The UUID of the player to be removed
     * @returns {Promise<Object>} - Response status
     */
    async removePlayerFromHomeBlanket(matchId, playerId) {
        return this.apiService.delete(`matches/${matchId}/${playerId}/homeBlankett/removePlayer`);
    }

    /**
     * Remove a player from the away blanket
     * @param {string} matchId - The UUID of the match
     * @param {string} playerId - The UUID of the player to be removed
     * @returns {Promise<Object>} - Response status
     */
    async removePlayerFromAwayBlanket(matchId, playerId) {
        return this.apiService.delete(`matches/${matchId}/${playerId}/awayBlankett/removePlayer`);
    }

    /**
     * Start a game
     * @param {string} matchId - The UUID of the match
     * @returns {Promise<Object>} - Response status
     */
    async startGame(matchId) {
        return this.apiService.patch(`matches/${matchId}/startGame`);
    }

    /**
     * End the first half of a game
     * @param {string} matchId - The UUID of the match
     * @returns {Promise<Object>} - Response status
     */
    async endFirstHalf(matchId) {
        return this.apiService.patch(`matches/${matchId}/endFirstHalf`);
    }

    /**
     * Start the second half of a game
     * @param {string} matchId - The UUID of the match
     * @returns {Promise<Object>} - Response status
     */
    async startSecondHalf(matchId) {
        return this.apiService.patch(`matches/${matchId}/startSecondHalf`);
    }

    /**
     * End a game
     * @param {string} matchId - The UUID of the match
     * @returns {Promise<Object>} - Response status
     */
    async endGame(matchId) {
        return this.apiService.patch(`matches/${matchId}/endGame`);
    }

    /**
     * Submit a game report
     * @param {string} matchId - The UUID of the match
     * @param {string} reportText - The match report text
     * @returns {Promise<Object>} - Response status
     */
    async submitGame(matchId, reportText) {
        const data = { text: reportText };
        return this.apiService.patch(`matches/${matchId}/submit`, data);
    }

    /**
     * Mark a game as a no-show
     * @param {string} matchId - The UUID of the match
     * @param {string} winningTeam - The team that won due to no-show ("home" or "away")
     * @returns {Promise<Object>} - Response status
     */
    async noShowGame(matchId, winningTeam) {
        const data = { winningTeam };
        return this.apiService.patch(`matches/${matchId}/noShowGame`, data);
    }

    /**
     * Mark a game as abandoned (spielabbruch)
     * @param {string} matchId - The UUID of the match
     * @returns {Promise<Object>} - Response status
     */
    async spielAbbruch(matchId) {
        return this.apiService.patch(`matches/${matchId}/spielabbruch`);
    }

    /**
     * Complete the match and update team points based on the result
     * @param {string} matchId - The UUID of the match
     * @returns {Promise<Object>} - Response status
     */
    async completeMatch(matchId) {
        return this.apiService.patch(`matches/${matchId}/done`);
    }

    /**
     * Toggle the dress for a team's blanket in a match
     * @param {string} matchId - The UUID of the match
     * @param {string} team - The team to toggle dress for ("home" or "away")
     * @returns {Promise<Object>} Response status
     */
    async toggleDress(matchId, team) {
        const toggleData = { team };
        return this.apiService.post(`matches/${matchId}/toggle`, toggleData);
    }

}

export default MatchController;
