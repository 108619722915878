// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_control__wmeA3 {
  position: absolute;
  height: -moz-fit-content;
  height: fit-content;
  width: 40px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  color: var(--highlight);
  display: flex;
  align-items: center;
}
.styles_control__wmeA3.styles_ltr__BUamS.styles_left__tWE2g {
  left: 0;
  padding-left: 8px;
  justify-content: flex-start;
}
.styles_control__wmeA3.styles_ltr__BUamS.styles_right__BB15H {
  right: 0;
  padding-right: 8px;
  justify-content: flex-end;
}
.styles_control__wmeA3.styles_rtl__oxlqn.styles_left__tWE2g {
  right: 0;
  padding-right: 8px;
  justify-content: flex-start;
}
.styles_control__wmeA3.styles_rtl__oxlqn.styles_right__BB15H {
  left: 0;
  padding-left: 8px;
  justify-content: flex-end;
}
.styles_control__wmeA3 button {
  opacity: 0.5;
  transition: opacity var(--transition);
}
.styles_control__wmeA3 button:hover {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/ui/SwiperControls/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,wBAAA;EAAA,mBAAA;EACA,WAAA;EACA,QAAA;EACA,2BAAA;EACA,UAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;AACJ;AAEQ;EACI,OAAA;EACA,iBAAA;EACA,2BAAA;AAAZ;AAGQ;EACI,QAAA;EACA,kBAAA;EACA,yBAAA;AADZ;AAMQ;EACI,QAAA;EACA,kBAAA;EACA,2BAAA;AAJZ;AAOQ;EACI,OAAA;EACA,iBAAA;EACA,yBAAA;AALZ;AASI;EACI,YAAA;EACA,qCAAA;AAPR;AASQ;EACI,UAAA;AAPZ","sourcesContent":[".control {\n    position: absolute;\n    height: fit-content;\n    width: 40px;\n    top: 50%;\n    transform: translateY(-50%);\n    z-index: 2;\n    color: var(--highlight);\n    display: flex;\n    align-items: center;\n\n    &.ltr {\n        &.left {\n            left: 0;\n            padding-left: 8px;\n            justify-content: flex-start;\n        }\n\n        &.right {\n            right: 0;\n            padding-right: 8px;\n            justify-content: flex-end;\n        }\n    }\n\n    &.rtl {\n        &.left {\n            right: 0;\n            padding-right: 8px;\n            justify-content: flex-start;\n        }\n\n        &.right {\n            left: 0;\n            padding-left: 8px;\n            justify-content: flex-end;\n        }\n    }\n\n    button {\n        opacity: .5;\n        transition: opacity var(--transition);\n\n        &:hover {\n            opacity: 1;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": `styles_control__wmeA3`,
	"ltr": `styles_ltr__BUamS`,
	"left": `styles_left__tWE2g`,
	"right": `styles_right__BB15H`,
	"rtl": `styles_rtl__oxlqn`
};
export default ___CSS_LOADER_EXPORT___;
