import React, { useEffect, useRef, useState, useMemo } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useThemeProvider } from '@contexts/themeContext';
import dayjs from 'dayjs';

const Navigator = ({ active, setActive, assignments }) => {
    const { theme, direction } = useThemeProvider();
    const containerRef = useRef(null);
    const [closestDate, setClosestDate] = useState(null);

    // Extract and sort by gameday using useMemo to avoid recalculations
    const uniqueDates = useMemo(() => {
        return assignments
            .map((assignment) => {
                const date = assignment.details?.date ? new Date(assignment.details.date) : null;
                if (!date || isNaN(date.getTime())) {
                    console.error("Invalid date:", assignment.details?.date);
                    return null;
                }
                return {
                    date,
                    gameday: assignment.details?.gameday || 'N/A',
                };
            })
            .filter(Boolean)
            .sort((a, b) => a.gameday - b.gameday); // Sort by gameday
    }, [assignments]);

    // Set the closest upcoming date based on today's date
    useEffect(() => {
        if (!closestDate && uniqueDates.length > 0) {
            const today = new Date();
            const upcomingDates = uniqueDates.filter(({ date }) => date >= today);
            if (upcomingDates.length > 0) {
                const closestUpcomingDate = upcomingDates.sort((a, b) => a.date - b.date)[0];
                setClosestDate(closestUpcomingDate.date);
                if (closestUpcomingDate.date.getDate() !== active) {
                    setActive(closestUpcomingDate.date.getDate());
                }
            } else {
                console.warn('No upcoming dates found.');
            }
        }
    }, [uniqueDates, closestDate, active, setActive]);

    // Ensure active element is always centered
    useEffect(() => {
        const container = containerRef.current;
        const activeElement = container?.querySelector(`.${styles.active}`);
        if (activeElement && container) {
            activeElement.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center',
            });
        }
    }, [active]);

    const handleDateClick = (date) => {
        setActive(date.getDate());
    };

    return (
        <div
            className={`${styles.navigator} ${theme === 'light' ? styles.light : styles.dark}`}
            ref={containerRef}
            style={{
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
                gap: '30px',
                padding: '10px',
                scrollSnapType: 'x mandatory',
            }}
        >
            {uniqueDates.map(({ date, gameday }, index) => (
                <div
                    key={index}
                    className={classNames(styles.slide, styles.navigator_item, styles[direction], {
                        [styles.active]: active === date.getDate(),
                    })}
                    onClick={() => handleDateClick(date)}
                    style={{
                        display: 'inline-block',
                        scrollSnapAlign: 'center',
                        padding: '10px',
                        cursor: 'pointer',
                        textAlign: 'center',
                        backgroundColor: active === date.getDate() ? 'black' : 'transparent',
                        color: active === date.getDate() ? 'white' : 'inherit',
                    }}
                >
                    <h4 className={styles.day}>{dayjs(date).format('D.M')}</h4>
                    <span className="label h6">{`Spieltag ${gameday}`}</span>
                </div>
            ))}
        </div>
    );
};

export default Navigator;
